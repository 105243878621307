<template>
  <div class="search">
    <div class="container">
      <div class="flex-center-start">
        <div class="search-title flex-center-start" @click="$router.go(-1)">
            <van-icon name="arrow-left" size="24px" color="#642EC7"/>
            <span>{{$t('common.search_2')}}</span>
        </div>
      </div>
      <a-divider />
      <div class="keywords">"{{ $route.query.keyword }}"</div>
      <div class="section-products">
        <a-row :gutter="[{ xs: 14, sm: 20, xxl: 30},{ xs: 14, sm: 20, xxl: 30}]" class="products">
          <a-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6" v-for="item in searchResults" :key="item.id">
          <div class="product-item" @click="handleBuyNow(item)"  :title="item.name||item.district_name">
            <div class="product-cover">
              <img class="product-img" :src="item.cover" :alt="item.name||item.district_name" :title="item.name||item.district_name"/>
              <img class="discount-icon" :style="{opacity:item.is_sale?1:0}" src="../assets/discount_icon.png" alt="">
            </div>
            <div class="product-info">
              <div class="product-name text-overflow-example">{{ item.name||item.district_name }}</div>
              <div class="product-lowest-price">
                <span class="product-price-from">{{ $t("common.from") }}</span>
                <span>{{ currency }} {{ item.lowest_price }}</span>
              </div>
              <div class="product-icon flex">
                <a-icon type="right" />
              </div>
            </div>
          </div>
          </a-col>
        </a-row>
      </div>
      <div v-show="isEmpty" class="empty-wrapper flex">
        <a-empty :image="require('@/assets/search_none.png')">
          <span slot="description" class="empty-text"> {{$route.query.keyword ? $t('common.search_3'):$t('common.search_4')}} </span>
        </a-empty>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SearchProductAPI } from '../api/api'
export default {
  name:'Search',
  data() {
    return {
      searchResults:[],
      isEmpty:false
    }
  },
  computed: {
    ...mapGetters("config", ["currency"]),
  },
  mounted(){
    this.init()
  },
  methods:{
    handleBuyNow({id,name}){
      this.$router.push({ name: 'Details', query: { id }});
      window._hmt.push(['_trackEvent', 'button' ,'click', `浏览套餐-${name}`]);
    },
    async init(){
      const {keyword,product_id} = this.$route.query
      if(!keyword.replace(/\s/g, '')) return this.searchResults = []
      const word = keyword.replace(/[()|\uFF08\uFF09\s]/g, "%")
      // const word = keyword.replace(/[()]/g, "%").trim()
      const params = {
        ...(product_id?{product_id}:{word})
      }
      const { data } = await SearchProductAPI(params)
      this.searchResults = data.list
      this.isEmpty = data.list<=0
    }
  },
}
</script>
<style lang="scss" scoped>
.search{
  cursor: pointer;
  min-height: 80vh;
  .keywords{
    padding: 0 10px;
    font-size: 20px;
    font-weight: 600;
    @include respond-to('phone'){
      font-size:14px;
    }
  }
  .search-title{
    color: $color;
    font-size:20px;
    font-weight: 600;
    margin-top:20px;
    @include respond-to('phone'){
      font-size:16px;
    }
  }
}
</style>